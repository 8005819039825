module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Astrocoders","short_name":"Astrocoders","description":"Go Beyond.","start_url":"http://astrocoders.com","background_color":"#000","theme_color":"#835FE2","display":"minimal-ui","icon":"src/img/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c5d890fb431b53a7c61800feccd65ac2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/i18n","languages":["en","br","es","zh","ru"],"defaultLanguage":"en","redirect":true,"redirectComponent":"/opt/build/repo/src/components/Redirect.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#835FE2","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-drift/gatsby-browser.js'),
      options: {"plugins":[],"appId":"bkbfytvikwtr"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-122635460-1","sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"astrocoders.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
